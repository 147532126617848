/* eslint-disable no-nested-ternary */
import { AecomDropDownComponent, IMenuItem, LayoutService } from '@aecom/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, BBJSearchService } from '@bbj/components';
import { combineLatest, Subscription } from 'rxjs';
import {
  ApplicationNavigation,
  Borough,
  BoroughNavigation,
  Contract,
  GlobalModule,
  GlobalNavigation,
  ITaskTab,
  SearchService,
} from 'src/app/api-generated';
import ContractService from 'src/app/services/contract.service';
import LocalTaskService from 'src/app/services/local-task.service';
import NavService from 'src/app/services/nav.service';
import environment from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export default class NavigationComponent implements OnInit {
  @ViewChild('boroughDropDown') boroughDropDownEL: AecomDropDownComponent;

  @ViewChild('contractDropDown') contractDropDownEL: AecomDropDownComponent;

  private boroughList: BoroughNavigation[];

  private globalList: GlobalNavigation[];

  selectSubscription: Subscription;

  boroughSelectionList: IMenuItem[] = [];

  boroughSelectionId = '';

  contractSelectionList: IMenuItem[] = [];

  contractSelectionId = '';

  leftNav: IMenuItem[] = [];

  globalNav: IMenuItem[] = [];

  bottomNav: IMenuItem[] = [];

  appItems: IMenuItem[] = [];

  isBoroughMenu = true;

  productname = 'Portal';

  productsubtitle = '';

  stage = environment.stage;

  productmaintitle =
    this.stage === 'staging' ? 'NYC BBJ PROGRAM - SANDBOX' : 'NYC BBJ PROGRAM';

  withIcons = true;

  copyright = '© NYC BBJ PROGRAM';

  taskList: ITaskTab[];

  isLoaded = false;

  isDBAdmin = false;

  showSearch = false;

  constructor(
    public authService: AuthService,
    private contractService: ContractService,
    public navService: NavService,
    public layoutService: LayoutService,
    private router: Router,
    public localTaskService: LocalTaskService,
    public searchService: SearchService,
    public bbjSearchService: BBJSearchService,
  ) {}

  ngOnInit(): void {
    if (!environment.production) {
      this.productmaintitle =
        environment.stage === 'staging'
          ? 'NYC BBJ PROGRAM - SANDBOX'
          : `${this.productmaintitle} - 6/20/2022 11:30 AM`;
    }
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.boroughList = this.navService.getBroughData().filter((b) => {
      return b.projects.length;
    });

    this.boroughList.sort((a, b) => a.boroughItem.Order - b.boroughItem.Order);
    this.globalList = this.navService.getGlobalData();
    this.globalList.sort((a, b) => a.globalModule.Order - b.globalModule.Order);

    this.globalList.forEach((g) => {
      const subManus: IMenuItem[] = [];

      g.applications.forEach((a) => {
        subManus.push({
          name: NavigationComponent.getAppName(a),
          id: a.applicationId,
          url: null,
          externalurl: a.url ?? '/error/comingSoon',
          children: [],
        });
      });

      if (g.globalModule.Order > 99) {
        this.bottomNav.push({
          name: g.globalModule.Name,
          id: g.globalModule.Guid,
          url: g.globalModule.UrlTemplate,
          children: subManus,
          externalicon: `icon-${g.globalModule.Icon}`.replace('.svg', ''),
        });
      } else {
        const newNav: IMenuItem = {
          name: g.globalModule.Name,
          id: g.globalModule.Guid,
          children: subManus,
          externalicon: `assets/icons/${g.globalModule.Icon}`,
        };
        if (subManus.length === 0) {
          if (g.globalModule.Embed) {
            newNav.url = g.globalModule.UrlTemplate;
          } else {
            newNav.externalurl = g.globalModule.UrlTemplate;
          }
        }
        this.globalNav.push(newNav);
      }
    });
    if(!environment.production)
    {
      console.log(this.navService.getUser().Permissions);
    }
    
    this.isDBAdmin = this.navService.getUser().Permissions.includes('_DB_User');
    this.showSearch = this.navService.getUser().Permissions.includes('BBJ-Search');

    this.globalNav.push({
      name: 'Onboarding',
      externalicon: 'assets/icons/bbj_onboarding_icon.svg',
      id: 'onboarding',
      externalurl:
        'https://apps.powerapps.com/play/e7e45d8e-50c2-4d1b-bafb-dd3cdafd5b66?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a',
    });
    this.appItems.push({
      name: 'Onboarding',
      icon: 'bbj_onboarding_icon',
      id: 'onboarding',
      externalurl:
        'https://apps.powerapps.com/play/e7e45d8e-50c2-4d1b-bafb-dd3cdafd5b66?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a',
    });
    const isAdmin = this.navService.getUser().Permissions.includes('_Admin');
    if (isAdmin) {
      this.appItems.push({
        name: 'Settings',
        icon: 'bbj_product_icon_settings',
        id: 'settings',
        externalurl: `https://${
          environment.production ? '' : 'dev.'
        }settings.bbjnyc.com/contracts`,
      });
      this.globalNav.push({
        name: 'Settings',
        icon: 'bbj_product_icon_settings',
        id: 'settings',
        externalurl: `https://${
          environment.production ? '' : 'dev.'
        }settings.bbjnyc.com/contracts`,
      });
    }
    this.globalNav.push({
      name: 'Support',
      externalicon: 'assets/icons/bbj_action_app_support.svg',
      id: 'support',
      externalurl:
        'https://apps.powerapps.com/play/c04c6371-a3a6-4b25-a94c-9fe7fd35a16b?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a',
    });
    this.appItems.push({
      name: 'Support',
      icon: 'icon-action_app_support',
      id: 'support',
      externalurl:
        'https://apps.powerapps.com/play/c04c6371-a3a6-4b25-a94c-9fe7fd35a16b?tenantId=bfb413d6-9a23-4cd3-a58d-e141aaf2948a',
    });

    if (this.isDBAdmin) {
      this.isLoaded = true;
    } else {
      this.taskList = this.localTaskService.getItems();
      this.isLoaded = true;
    }

    this.selectSubscription = combineLatest([
      this.navService.selectedItem$,
      this.contractService.selectedItem$,
    ]).subscribe(([selectedBorough, selectedContract]) => {
      this.resetSideNav(selectedBorough, selectedContract);
    });
  }

  ngOnDestroy(): void {
    this.selectSubscription.unsubscribe();
  }

  resetSideNav(selectedBorough: Borough | GlobalModule, selectedContract: Contract): void {
    this.boroughSelectionList = [];
    this.boroughSelectionId = '';
    this.contractSelectionList = [];
    this.contractSelectionId = '';
    this.leftNav = [];

    this.boroughSelectionList = this.boroughList.map((b) => {
      return {
        id: b.boroughItem.Guid,
        name: b.boroughItem.Name,
        checked: false,
      };
    });

    if (selectedBorough) {
      const activeBorough = this.boroughSelectionList.filter((b) => {
        return b.id === selectedBorough.Guid;
      });

      if (activeBorough.length) {
        this.boroughSelectionId = activeBorough[0].id;
        activeBorough[0].checked = true;

        const projectList = this.boroughList.filter((n) => {
          return n.boroughItem.Guid === activeBorough[0].id;
        })[0].projects;

        this.contractSelectionList = projectList.map((p) => {
          return {
            contract: p.contractItem,
            id: p.contractItem.Guid,
            name: p.contractItem.Name,
            checked: false,
          };
        });

        if (selectedContract) {
          const activeProject = this.contractSelectionList.filter((c) => {
            return c.id === selectedContract.Guid;
          });

          if (activeProject.length) {
            this.contractSelectionId = activeProject[0].id;
            activeProject[0].checked = true;

            const applicationList = projectList.filter((p) => {
              return p.contractItem.Guid === activeProject[0].id;
            })[0].applications;

            this.leftNav = applicationList.map((a) => {
              return {
                name: a.applicationName,
                id: a.applicationId,
                url: null,
                externalurl: a.url ?? '/error/comingSoon',
                // icon: `${a.logo} ${a.color}`,
                children: [],
                externalicon: `assets/icons/${a.logo}`,
              };
            });
          }
        }
      }
    }
  }

  onBoroughSelected(e: IMenuItem | IMenuItem[]): void {
    if (e && !Array.isArray(e)) {
      const item = e;
      this.navService.setSelectedId(item.id);

      const selectedBorough = this.boroughList.find((b) => {
        return b.boroughItem.Guid === item.id;
      });

      // console.log(JSON.stringify(selectedBorough));

      if (selectedBorough && selectedBorough.projects.length >0 ) {
        if(selectedBorough.boroughItem.DefaultContract) {
          const defaultProject = selectedBorough.projects?.find((p)=>{return p.contractItem.Code===selectedBorough.boroughItem.DefaultContract});
          if(defaultProject)
          {
            this.contractService.setSelected(defaultProject.contractItem);
          } else {
            this.contractService.setSelected(null);
          }
        }
        if(selectedBorough.projects.length ===1)
        {
          this.contractService.setSelected(
            selectedBorough.projects[0].contractItem,
          );
        }
      } else {
        this.contractService.setSelected(null);
      }
    }
  }

  onContractSelected(e: IMenuItem | IMenuItem[]): void {
    if (e && !Array.isArray(e)) {
      this.contractService.setSelected(e);
    }
  }

  navigate(node: IMenuItem): void {
    if (
      node.id.toLocaleLowerCase() === 'f5960107-e029-11eb-a7b4-ace2d34b68ef'
    ) {
      this.unselectOtherNodes(node);
      this.onBoroughSelected(node);
      return;
    }

    // console.log(JSON.stringify(node));
    if (node.url && !node.externalurl) {
      this.navigateByUrl(node);
    } else if (node.externalurl || Boolean(node.url)) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(node.externalurl, '_blank');
    } else {
      this.navigateByUrl(node);
    }
  }

  navigateByUrl(node: IMenuItem): void {
    this.unselectOtherNodes(node);
    this.router.navigateByUrl(node.url);
  }

  static getAppName(appObj: ApplicationNavigation): string {
    switch (appObj.applicationId) {
      case '8a013201-0baf-11ea-917a-027e1b1809fa':
        return appObj.applicationName;
      case '8a013205-0baf-11ea-917a-027e1b1809fa':
        return 'AHJV hub';
      default:
        return NavigationComponent.capSentence(appObj.applicationName);
    }

    // return BaseComponent.capSentence(appObj.applicationName);
  }

  static capSentence(str: string): string {
    const wordsArray = str.toLowerCase().split(' ');

    const capsArray = wordsArray.map((word) => {
      return word[0].toUpperCase() + word.slice(1);
    });

    return capsArray.join(' ');
  }

  unselectOtherNodes(selected: IMenuItem): void {
    if (this.contains(this.leftNav, selected)) {
      this.uncheckAll(this.globalNav);
    } else if (this.contains(this.globalNav, selected)) {
      this.uncheckAll(this.leftNav);
    }
  }

  private uncheckAll(nodes: IMenuItem[]) {
    nodes.forEach((item) => {
      if (item.children) {
        this.uncheckAll(item.children);
      }

      item.checked = false;
    });
  }

  private contains(nodes: IMenuItem[], selected: IMenuItem) {
    return nodes.some((node) => {
      if (node.id === selected.id) {
        return true;
      }

      if (node.children) {
        return this.contains(node.children, selected);
      }

      return false;
    });
  }

  getKeywords(word: string): void {
    this.searchService.getKeywords({keyword:word}).subscribe((results)=>{
      this.bbjSearchService.setKeywords(results);
    });
  }

  goSearch(): void {
    this.router.navigateByUrl(`/search`);
  }
}
