import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import AccessDeniedComponent from './content/auth/access-denied/access-denied.component';
import NavigationComponent from './content/navigation/navigation.component';
import DataPrivacyComponent from './content/data-privacy/data-privacy.component';
import ErrorComponent from './content/error/error.component';
import PortalComponent from './content/portal/portal.component';
import SingoutComponent from './content/singout/singout.component';
import ErrorResolve from './resolvers/error-resolver';
import LoginUserResolve from './resolvers/login-user-resolver';
import RouteParams from './shared/route-params';
import { BrowserUtils } from '@azure/msal-browser';
import { SearchResultsComponent } from '@bbj/components';
import SearchResolver from '@resolvers/search.resolver';

const routes: Routes = [
  {
    path: 'unauthorized',
    component: AccessDeniedComponent,
    pathMatch: 'full',
  },
  {
    path: `error/:${RouteParams.ErrorCode}`,
    component: ErrorComponent,
    resolve: { loginuser: ErrorResolve },
  },
  {
    path: '',
    component: NavigationComponent,
    canActivate: [MsalGuard],
    runGuardsAndResolvers: 'always',
    resolve: { loginuser: LoginUserResolve },
    children: [
      {
        path: '',
        component: PortalComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'onboarding',
        component: PortalComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'dataprivacy',
        component: DataPrivacyComponent,
        canActivate: [MsalGuard],
      },
      {
        path: `search`,
        component: SearchResultsComponent,
        runGuardsAndResolvers: "always",
        canActivate: [MsalGuard],
        resolve: { itemData: SearchResolver },
      },
    ],
  },
  {
    path: 'sign-out',
    component: SingoutComponent,
    pathMatch: 'full',
  },
  {
    path: 'logout',
    component: SingoutComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'error/404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled'
    }),
  ],

  exports: [RouterModule],
})
export default class AppRoutingModule {}
